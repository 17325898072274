import {Fragment} from 'react'
import {VmView, VmText} from 'components'
import {isObject, toPascalCase} from 'utils'
import classNames from 'classnames'
import InputCustom from './InputCustom'

export const textCase = (d, txt) => {
  if (!!d?.Pascal) {
    if (txt.includes(d.Pascal)) {
      return txt
        .split(d.Pascal)
        .map(nt => toPascalCase(nt))
        .join(' ')
    }
    return toPascalCase(d.Pascal + txt, d.Pascal)
  }
  return txt
}

export const defaultClassNames = [
  'm-4 border-2 border-slate-200 rounded-2xl overflow-hidden md:rounded-3xl',
  'p-4 text-left justify-start text-white bg-blue-500 font-bold text-2xl',
  'flex flex-col md:justify-between ',
  'w-full first:border-t-0 border-slate-200 ',
]

const [containerClassName, titleClassName] = defaultClassNames

export const classSparator = defaultClassNames[3]

export const Items = (data, index) => {
  const childLength = index?.split('Child-')?.length
  var classItem = defaultClassNames[2]
  classItem +=
    childLength === 2
      ? 'my-2 '
      : childLength === 3
      ? 'ml-4 md:ml-0 my-2 '
      : 'm-4 '
  const subdata = Object.keys(data)
  const isSingle = d => d.match(/^[a-z]{1}$/),
    isCustomView = data?.customView === true

  if (isCustomView) {
    return <Fragment key={`Item-${index}`}>{data.children(index)}</Fragment>
  }
  return (
    <Fragment key={`Item-${index}`}>
      {subdata.map((sm, subindex) => {
        if (sm === 'visible') {
          return null
        }

        const isRow = data[sm].mode == 'row'
        if (isRow) {
          let itemkeys = Object.keys(data[sm].items)
          return (
            <VmView
              key={`Row-${index}-${subindex}-${sm}`}
              className="flex flex-col md:flex-row md:items-start text-slate-500"
            >
              {itemkeys.map((item, i) => {
                let inputData = data[sm].items[item]
                return (
                  <InputCustom
                    key={`RowInput-${index}-${subindex}-${sm}-${i}`}
                    data={inputData.inputCustom}
                    title={textCase('Pascal', item)}
                    usetitle={true}
                    label={inputData?.inputCustom?.ariaLabel || sm}
                    prefix={inputData.inputCustom?.prefix}
                    suffix={inputData.inputCustom?.suffix}
                    outerprefix={inputData.inputCustom?.outerprefix}
                    outersuffix={inputData.inputCustom?.outersuffix}
                    propsInput={inputData.inputCustom?.propsInput}
                    classItem={`${classItem} flex-1`}
                  />
                )
              })}
            </VmView>
          )
        }

        const isInputCustom = !!data[sm]?.inputCustom,
          isSingleInputCustom = sm === 'inputCustom',
          isChildren = sm === 'children',
          title = textCase(data[sm][isInputCustom ? 'input' : ''], sm),
          key = `Sub-${index}-${subindex}-${sm}`

        if (isChildren) {
          return <Fragment key={key}>{data.children()}</Fragment>
        }
        if (!isObject(data[sm])) {
          return <VmView key={key}>{title}</VmView>
        }
        if (isInputCustom || isSingleInputCustom) {
          classItem += 'py-1 text-slate-500 font-normal text-base '
          const inputData = !isSingleInputCustom ? data[sm] : data
          return (
            <InputCustom
              key={key}
              data={inputData.inputCustom}
              title={title}
              usetitle={isInputCustom}
              label={inputData?.inputCustom?.ariaLabel || sm}
              prefix={inputData.inputCustom?.prefix}
              suffix={inputData.inputCustom?.suffix}
              outerprefix={inputData.inputCustom?.outerprefix}
              outersuffix={inputData.inputCustom?.outersuffix}
              propsInput={inputData.inputCustom?.propsInput}
              classItem={inputData.inputCustom?.classItem || classItem}
              childLength={childLength}
            />
          )
        }
        if (isSingle(sm)) {
          return (
            <Fragment key={key}>
              <VmView className={classNames(classItem, 'text-xl')}>
                {Object.keys(data[sm]).map((sim, idxsim) => {
                  const singleKey = `${key}-${sim}-${idxsim}`
                  const singleTitle = textCase({Pascal: '-'}, sim)
                  return (
                    <Fragment key={singleKey}>
                      <VmText className="text-blue-500 font-bold">
                        {singleTitle}
                      </VmText>
                      {Items(
                        data[sm][sim],
                        `Child-${subindex}-${idxsim}-${sim}`
                      )}
                    </Fragment>
                  )
                })}
              </VmView>
              <VmView className={classNames(classSparator, 'border-t-2')} />
            </Fragment>
          )
        }
        // Default
        return (
          <VmView
            key={key}
            className="py-1 text-slate-600 font-semibold text-lg"
          >
            <VmText className="font-semibold">
              {textCase({Pascal: '-'}, title)}
            </VmText>
            {Object.keys(data[sm]).map((sim, idxsim) => {
              if (!isObject(data[sm][sim])) {
                return null
              }
              return Items(
                {[sim]: data[sm][sim]},
                `Child-${key}-${title}-${idxsim}-${sim}`
              )
            })}
          </VmView>
        )
      })}
    </Fragment>
  )
}

export const ItemCard = ({data, title, index}) => {
  const key = `C-${title}-${index}`
  return (
    <Fragment key={key}>
      <VmView className={classNames(classSparator, '')} />
      <VmView className={data?.containerClassName || containerClassName}>
        <VmText className={data?.titleClassName || titleClassName}>
          {textCase({Pascal: '-'}, title)}
        </VmText>
        {Items(data, key)}
      </VmView>
    </Fragment>
  )
}

export default function HandlerForm({data = {}}) {
  const keys = Object.keys(data)
  return keys.map((title, index) => {
    console.log(`Processing title: ${title}, index: ${index}`)
    const key = `C-${title}-${index}`
    const isChildren = title === 'children'
    const isCustomView = data[title]?.customView === true
    const isInvisible = data[title]?.visible === false

    if (isInvisible) {
      console.log(`Skipping invisible item: ${title}`)
      return null
    }
    if (isCustomView) {
      console.log(`Rendering custom view for: ${title}`)
      return <Fragment key={key}>{data[title].children(index)}</Fragment>
    }
    if (isChildren) {
      console.log(`Rendering children for: ${title}`)
      return <Fragment key={key}>{data.children(index)}</Fragment>
    }
    const childData = data[title]?.title || data[title]
    console.log(`Rendering ItemCard for: ${title} with data:`, childData)
    return <ItemCard data={childData} title={title} key={key} index={index} />
  })
}
