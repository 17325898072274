import {VmButton, VmIcons, VmInput, VmView} from 'components'
import {PiPill} from 'react-icons/pi'
import {CiBarcode} from 'react-icons/ci'
import {BsBoxSeam, BsInboxes} from 'react-icons/bs'

import {useEffect, useState} from 'react'

const defaultData = {
  nmObat: '-',
  kdObat: '-',
  kdObatSK: '-',
  jmlObat: 0,
  signa1: 0,
  signa2: 0,
  sedia: 0,
  qty: 0,
}
export default function CardItem({
  showqty = true,
  showadd = true,
  showplus = true,
  showminus = true,
  showdelete = true,
  disabled = false,
  label = '',
  data = defaultData,
  index = 0,
}) {
  const [
      {nmObat = '-', kdObat = '-', kdObatSK = '-', jmlObat = 0, qty = 0, signa1 = 0, signa2 = 0, flagcart = '', sedia: stok = 0},
      setdata,
    ] = useState(data),
    classPM =
      'relative flex justify-center items-center rounded-lg bg-blue1-payment text-white overflow-hidden min-w-fit w-8 h-8 cursor-pointer p-[1px]',
    classHTitle =
      'text-prevent-select text-xs font-medium text-gray2-payment mb-1',
    classCSect = 'relative flex flex-row justify-between p-4 pt-0'
  const showtotal = showqty || showplus || showminus
  const havestok = stok > 0

  useEffect(() => {
    setdata(data)
  }, [data])

  return (
    <VmView className="relative flex flex-col overflow-hidden !text-black2-payment border-gray-200 border-2 bg-white mb-4 last:mb-0 rounded-xl rounded-t-2xl">
      {/* Head */}
      <VmView className="relative flex bg-white px-4 py-3 rounded-xl text-prevent-select">
        <VmView className="flex flex-col mr-auto">
          {/* Kode */}
          <VmView className="flex mr-auto gap-2">
            <VmView className="w-8 h-8 pt-1">
              <CiBarcode size={32} />
            </VmView>
            <VmView className="flex flex-col mr-auto">
              <VmView className="text-sm">{'Kode Obat'}</VmView>
              <VmView className="font-bold break-all">{kdObat}</VmView>
            </VmView>
          </VmView>
          {/* Kode Obat SK*/}
          {kdObatSK != '-' && label == '-obat' && (
          <VmView className="flex mr-auto gap-2">
            <VmView className="w-8 h-8 pt-1">
              <CiBarcode size={32} />
            </VmView>
            <VmView className="flex flex-col mr-auto">
              <VmView className="text-sm">{'Kode Obat SK'}</VmView>
              <VmView className="font-bold break-all">{kdObatSK || '-'}</VmView>
            </VmView>
          </VmView>
          )}
          {/* Name */}
          <VmView className="flex mr-auto gap-2">
            <VmView className="w-8 h-8 pt-1">
              <PiPill className="rotate-180" size={30} />
            </VmView>
            <VmView className="flex flex-col mr-auto">
              <VmView className="text-sm">{'Nama Obat'}</VmView>
              <VmView className="font-bold break-all">{nmObat}</VmView>
            </VmView>
          </VmView>
          {/* Aturan Pakai */}
          {kdObatSK != '-' && signa1 != 0 && signa2 != 0 && (
          <VmView className="flex mr-auto gap-2">
            <VmView className="w-8 h-8 pt-1">
              <PiPill className="rotate-180" size={30} />
            </VmView>
            <VmView className="flex flex-col mr-auto">
              <VmView className="text-sm">{'Aturan Pakai'}</VmView>
              <VmView className="font-bold break-all">{`${signa1} x ${signa2}`}</VmView>
            </VmView>
          </VmView>
          )}
          {/* Jumlah */}
          {kdObatSK != '-' && jmlObat > 0 && (
          <VmView className="flex mr-auto gap-2">
            <VmView className="w-8 h-8 pt-1">
              <PiPill className="rotate-180" size={30} />
            </VmView>
            <VmView className="flex flex-col mr-auto">
              <VmView className="text-sm">{'Jumlah'}</VmView>
              <VmView className="font-bold break-all">{jmlObat}</VmView>
            </VmView>
          </VmView>
          )}
          {kdObatSK == '-' && qty > 0 && (
            <>
              <VmView className="flex mr-auto gap-2">
                <VmView className="w-8 h-8 pt-1">
                  <PiPill className="rotate-180" size={30} />
                </VmView>
                <VmView className="flex flex-col mr-auto">
                  <VmView className="text-sm">{'Signa 1'}</VmView>
                  {/* <VmView className="font-bold break-all">{`${signa1} x ${signa2}`}</VmView> */}
                  <VmView className="relative flex flex-row h-5 px-2 !w-[4rem] overflow-hidden">
                    <VmInput
                      typeInput="text"
                      label={`input-signa1${label}-##-${index}-##-${flagcart}`}
                      className="absolute text-center !py-0 !px-0 font-bold !w-[1.5rem] !h-[-webkit-fill-available] !bg-transparent border-0"
                      hidering={true}
                      defaultValue={signa1}
                    />
                  </VmView>
                </VmView>
              </VmView>
              <VmView className="flex mr-auto gap-2">
                <VmView className="w-8 h-8 pt-1">
                  <PiPill className="rotate-180" size={30} />
                </VmView>
                <VmView className="flex flex-col mr-auto">
                  <VmView className="text-sm">{'Signa 2'}</VmView>
                  
                  <VmView className="relative flex flex-row h-5 px-2 !w-[4rem] overflow-hidden">
                    <VmInput
                      typeInput="text"
                      label={`input-signa2${label}-##-${index}-##-${flagcart}`}
                      className="absolute text-center !py-0 !px-0 font-bold !w-[1.5rem] !h-[-webkit-fill-available] !bg-transparent border-0"
                      hidering={true}
                      defaultValue={signa2}
                    />
                  </VmView>
                </VmView>
              </VmView>
            </>
          )}
        </VmView>
        {/* Left Header */}
        <VmView className="text-sm">
          {/* Stok */}
          {!disabled && (
            <VmView className="flex mr-auto gap-2">
              <VmView className="w-8 h-8 pt-1">
                <BsBoxSeam size={30} />
              </VmView>
              <VmView className="flex flex-col mr-auto">
                <VmView className="text-sm">{'Stok'}</VmView>
                <VmView className="font-bold break-all">{stok}</VmView>
              </VmView>
            </VmView>
          )}
          {/* Jumlah */}
          {disabled && (
            <VmView className="flex mr-auto gap-2">
              <VmView className="w-8 h-8 pt-1">
                <BsInboxes size={30} />
              </VmView>
              <VmView className="flex flex-col mr-auto">
                <VmView className="text-sm">{'Jumlah'}</VmView>
                <VmView className="font-bold break-all">{qty}</VmView>
              </VmView>
            </VmView>
          )}
        </VmView>
      </VmView>
      {/* Footer */}
      {!disabled && (
        <VmView className={`${classCSect}`}>
          {showtotal && (
            <VmView
              key={`showtotal-k${label}-##-${index}-##-${flagcart}-${qty}`}
              className="w-[-webkit-fill-available] pr-3 last:pr-0"
            >
              {kdObatSK == '-' && havestok ? (
                <>
                  <VmView className="flex flex-row items-center">
                    <VmView className={classPM}>
                      <VmIcons
                        style={{strokeWidth: '4px'}}
                        name="VmMinusIcon"
                        variant="outline"
                        size={11}
                      />
                      {qty === 1 ? (
                        <VmView
                          className={
                            '!bg-gray0-stripes absolute inset-0 cursor-default'
                          }
                        />
                      ) : (
                        <VmView
                          label={`minus-item${label}-##-${index}-##-${flagcart}`}
                          className={'!bg-transparent absolute inset-0'}
                        />
                      )}
                    </VmView>
                    <VmView className="relative flex flex-row h-5 px-2 !w-[4rem] overflow-hidden">
                      <VmInput
                        typeInput="text"
                        label={`input-qty${label}-##-${index}-##-${flagcart}`}
                        className="absolute text-center !py-0 !px-0 font-bold !w-[3rem] !h-[-webkit-fill-available] !bg-transparent border-0"
                        hidering={true}
                        defaultValue={qty}
                      />
                    </VmView>
                    <VmView className={classPM}>
                      <VmIcons
                        style={{strokeWidth: '4px'}}
                        label={`plus-item${label}-##-${index}-##-${flagcart}`}
                        name="VmPlusIcon"
                        variant="outline"
                        size={12}
                      />
                      {qty >= stok ? (
                        <VmView
                          className={
                            '!bg-gray0-stripes absolute inset-0 cursor-default'
                          }
                        />
                      ) : (
                        <VmView
                          label={`plus-item${label}-##-${index}-##-${flagcart}`}
                          className={'!bg-transparent absolute inset-0'}
                        />
                      )}
                    </VmView>
                  </VmView>
                  {kdObatSK == '-' && (
                  <VmButton
                    label={`add-item${label}-##-${index}-##-${flagcart}`}
                    className="flex justify-center text-sm min-w-[-webkit-fill-available] !rounded-xl font-semibold active:!text-white hover:!text-white !text-white !border-blue1-payment border first:!mr-0 first:ml-auto mt-4" 
                    hidering={true}
                    // disabled={!havestok}
                  >
                    Pilih
                  </VmButton>
                  )}
                </>
              ) : (
                <>
                  {showadd && false && (
                    <VmButton
                      label={`add-item${label}-##-${index}-##-${flagcart}`}
                      className="flex justify-center text-sm min-w-[-webkit-fill-available] !rounded-xl font-semibold active:!text-white hover:!text-white !text-white !border-blue1-payment border first:!mr-0 first:ml-auto"
                      hidering={true}
                      // disabled={!havestok}
                    >
                      Pilih
                    </VmButton>
                  )}
                </>
              )}
            </VmView>
          )}
          {showdelete && kdObatSK != '-' && (
            <VmView className="min-w-fit">
              {/* <VmView className={classHTitle}>&nbsp;</VmView> */}
              <VmView className="relative flex flex-row-reverse">
                <VmView
                  label={`delete-item${label}-##-${index}-##-${flagcart}`}
                  className="flex p-[0.3rem] justify-center bg-red5-payment items-center rounded-md w-8 h-8 cursor-pointer"
                >
                  <VmIcons
                    label={`delete-item${label}-##-${index}-##-${flagcart}`}
                    style={{strokeWidth: '2px'}}
                    className="text-white w-full h-full"
                    name="VmTrashIcon"
                    variant="outline"
                  />
                </VmView>
              </VmView>
            </VmView>
          )}
        </VmView>
      )}
      {/* {!havestok && !disabled && (
        <VmView className="!bg-red6-stripes absolute inset-0" />
      )} */}
    </VmView>
  )
}
