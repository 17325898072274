import {useEffect, useMemo, useState} from 'react'
import {
  getLclStorage,
  removeLclStorage,
  setLclStorage,
} from 'utils/localstorage'
import {DataObat} from './classes'

const useCarts = ({
  name = 'carts',
  flags: defaultflags = 'id',
  flagstok: defaultflagstok = 'obatid',
}) => {
  const opt = {type: 2}
  const [update, setupdate] = useState(() => {
    const storedValue = getLclStorage(`${name}-t`, {})
    return storedValue ? storedValue : Date.now()
  })
  const setlclcarts = data => {
      const newupdate = Date.now()
      setLclStorage(`${name}-t`, newupdate, {})
      setLclStorage(name, JSON.stringify(data), opt)
    },
    getlclcarts = () => {
      const data = getLclStorage(name, opt)
      return data ? JSON.parse(data) : null
    },
    clearlclcarts = () => setLclStorage(name, JSON.stringify({list: []}), opt),
    removelclcarts = () => removeLclStorage(name)
  const [cart, carts, cartstoks] = useMemo(() => {
    const carts = getlclcarts()?.list || []
    const cart = carts.reduce(
      (a, b, c) =>
        b[defaultflags]
          ? Object.assign(a, {[b[defaultflags]]: {...b, index: c}})
          : a,
      {}
    )
    // Stok Item and Item in racikan
    const flags = defaultflagstok
    // const asd = [].reduce
    const cartstoks = carts.reduce((a, b) => {
      var stok = 0
      var sisastok = 0
      var qtyincart = 0
      if (!!a[b[flags]]) {
        a[b[flags]]['stok'] = parseFloat(b?.stok || 0)
        a[b[flags]]['qtyincart'] =
          parseFloat(a[b[flags]]?.qtyincart || 0) +
          parseFloat(b?.qty || 0) *
            parseFloat(b?.defaultdata?.sodhasilkonversi || 1)
        a[b[flags]]['sisastok'] = a[b[flags]]['stok'] - a[b[flags]]['qtyincart']
      } else {
        stok = parseFloat(b?.stok || 0)
        qtyincart =
          parseFloat(a?.qtyincart || 0) +
          parseFloat(b?.qty || 0) *
            parseFloat(b?.defaultdata?.sodhasilkonversi || 1)
        sisastok = parseFloat(b?.stok || 0) - qtyincart
        a = Object.assign(a, {
          [b[flags]]: {
            stok,
            sisastok,
            qtyincart,
          },
        })
      }
      return a
    }, {})
    return [cart, carts, cartstoks]
  }, [name, defaultflags, update])
  const checkupdate = () => {
    const storedValue = getLclStorage(`${name}-t`, {})
    // console.log('first', update, storedValue, Date.now())
    var intval = null
    intval = setInterval(() => {
      if (`${update}` !== `${storedValue}`) {
        setupdate(storedValue)
      }
      clearInterval(intval)
      checkupdate()
    }, 100)
  }
  useEffect(() => {
    const havedata = getlclcarts()
    if (!havedata) {
      setlclcarts({list: []})
    }
    checkupdate()
    return () => {}
  }, [])

  const setcarts = data => setlclcarts(data)
  const addtocarts = (item = new DataObat({})) => {
    const carts = getlclcarts()?.list || []
    setlclcarts({list: [...carts, item]})
  }
  const edititemcarts = (item = new DataObat({}), flags = defaultflags) => {
    const carts = getlclcarts()?.list || []
    const newcarts = carts,
      idx = carts.findIndex(fl => fl[flags] === item[flags])
    newcarts[idx] = item
    const prefflag = item[flags]
    if (item?.fieldkey?.length > 0) {
      const newflag = generateFlags(item?.defaultdata, item?.fieldkey)
      if (newflag !== prefflag) {
        newcarts[idx] = {...item, [flags]: newflag}
      }
    }
    setlclcarts({list: newcarts})
  }
  const removetocarts = (item = new DataObat({}), flags = defaultflags) => {
    const carts = getlclcarts()?.list || []
    setlclcarts({list: carts.filter(fl => fl[flags] !== item[flags])})
  }
  // const checkstok = (
  //   item = new DataObat({}),
  //   flags = defaultflags,
  //   flagstok = defaultflagstok
  // ) => {
  //   return cartstoks[flagstok]
  // }
  return {
    cart,
    carts,
    cartstoks,
    setcarts,
    setupdate,
    addtocarts,
    removetocarts,
    edititemcarts,
    setlclcarts,
    clearlclcarts,
    removelclcarts,
  }
}
const generateFlags = (item = new DataObat(), fields = ['obatid']) => {
  var flags = []
  fields.forEach(el => {
    if (item[el]) flags.push(item[el])
  })
  return flags.join('-')
}
export {useCarts, generateFlags}

export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
