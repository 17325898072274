import classNames from 'classnames'
import {VmButton, VmSpinner, VmView} from 'components'
import {isFunction} from 'utils'

export default function Footer({
  loading = false,
  title = `Buat Resep`,
  label = 'add-resep',
  labelLoading = 'add-resep-loading',
  className = '',
  onClick = () => {},
  addtiionalComponent = <></>,

  backButton = false,
  backHandler = () => {},
  backButtonTitle = 'kembali',
  actionHandler = () => {},
}) {
  const labelBtn = loading ? labelLoading : label

  return (
    <VmView
      className={classNames(
        'flex flex-row justify-end fixed bottom-0 left-0 right-0 bg-blue4-payment px-5 pb-5 pt-4',
        className,
        backButton && 'md:justify-between'
      )}
      onClick={onClick}
    >
      {backButton && (
        <VmButton
          className="text-sm hidden md:block"
          color="danger"
          onClick={backHandler}
        >
          {backButtonTitle}
        </VmButton>
      )}

      <VmButton
        label={labelBtn}
        className={classNames(
          'flex justify-center items-center !flex-none text-sm p-2 px-4 !min-w-[45%] !bg-blue1-payment !text-white'
        )}
        hidering={true}
        onClick={actionHandler}
      >
        {loading ? <VmSpinner size="sm" className="text-white" /> : title}
      </VmButton>
      {addtiionalComponent}
    </VmView>
  )
}
