import axios from 'axios'
import {VmText, VmView} from 'components'
import {API7} from 'config/apiurl'
import {ProgressFullScreen, VmHeader} from 'molecules'
import QueryString from 'qs'
import {useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import Footer from './Footer'
import AlertForm from 'template/Form/AlertForm'
import {defaultpropsalertform} from 'entries/alertform'

export default function AddEtiket() {
  const [loading, setLoading] = useState(false)
  const [propsalertform, setpropsalertform] = useState(defaultpropsalertform)
  const [form, setform] = useState({etinama: '', etiketerangan: ''})

  const location = useLocation()
  const navigate = useNavigate()

  const closeAlert = () => setpropsalertform(defaultpropsalertform)
  const showAlert = (
    label = 'Tejadi kesalahan saat menyimpan etiket, silakan coba lagi !',
    type = 3,
    onConfirm = closeAlert
  ) =>
    setpropsalertform({
      ...propsalertform,
      type,
      visible: true,
      children: label,
      onConfirm: onConfirm,
      onClose: closeAlert,
      noText: false,
      yesText: 'Ok',
    })

  const fetchData = async payload => {
    setLoading(true)
    try {
      const {data} = await axios.post(
        API7 + '/apetiket/create',
        QueryString.stringify(payload)
      )

      if (data.status === 1) {
        showAlert('Berhasil menyimpan data etiket !', 4, () => {
          navigate(-1)
        })
      } else {
        return showAlert(
          data?.message ||
            'Tejadi kesalahan saat menyimpan etiket, silakan coba lagi !'
        )
      }
    } catch (e) {
      showAlert()
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <VmView className="h-screen">
        <VmView className="md:hidden fixed top-0 left-0 right-0 p-5 bg-blue4-payment">
          <VmHeader
            HeaderMode="!text-black2-payment"
            HeaderButtonVersion={0}
            HeaderName="Menu"
            HeaderType={'Buat Etiket'}
          />
        </VmView>

        <VmView className="px-5 md:pt-5 pt-[6.7rem] bg-blue4-payment h-full">
          <VmView className="bg-white p-6 rounded-2xl text-xs text-black2-payment">
            <VmText>Nama Etiket</VmText>
            <input
              type="text"
              className="my-2 py-2 !text-xs !ring-transparent rounded-md bg-gray0-payment w-full border-gray0-payment"
              value={form.etinama}
              onChange={e => setform({...form, etinama: e.target.value})}
            />

            <VmText>Catatan</VmText>
            <input
              type="text"
              className="my-2 py-2 !text-xs !ring-transparent rounded-md bg-gray0-payment w-full border-gray0-payment"
              value={form.etiketerangan}
              onChange={e => setform({...form, etiketerangan: e.target.value})}
            />
          </VmView>
        </VmView>

        <Footer
          title="Tambah"
          backButton
          backButtonTitle="Kembali"
          backHandler={() => navigate(-1)}
          actionHandler={() => {
            if (!form.etinama || !form.etiketerangan) {
              return showAlert('Semua kolom harus diisi !')
            } else {
              return fetchData({
                a: location.state?.a,
                reg: location.state?.reg,
                data: form,
              })
            }
          }}
        />
      </VmView>

      <ProgressFullScreen visible={loading} />
      <AlertForm {...propsalertform} />
    </>
  )
}
