import {VmButton, VmInput, VmModal, VmText, VmView} from 'components'
import {ProgressFullScreen, VmHeader} from 'molecules'
import React, {useRef, useState} from 'react'
import Footer from './Footer'
import {BottomSheet} from 'react-spring-bottom-sheet'
import useAddEventListener from 'utils/useAddEventListener'
import {defaultpropsbottomsheet} from 'entries/bottomsheet'
import classNames from 'classnames'
import {TemplateListInputItems} from 'template'
import {isEmpty, isFunction} from 'utils'
import {defaultpropsalertform} from 'entries/alertform'
import AlertForm from 'template/Form/AlertForm'
import {mAddPasien, mGetRmPasien} from './api'
import {useParams} from 'react-router-dom'
import {genderlist, statusmeriedlist} from './entries'
import InputType1 from 'template/Form/InputType1'
import QueryString from 'qs'
import axios from 'axios'
import moment from 'moment'

function InputIdSatusehat({inputValue = '', onClick = () => {}}) {
  let im = {
    title: 'ID Satu Sehat',
    placeholder: 'ID Satu Sehat',
    label: 'input-pasid_satusehat',
    field: 'pasid_satusehat',
    typeInput: 'text_button',
    buttonTitle: 'Cari',
    maxLength: 255,
    classNameInput: 'text-xs !max-h-10',
    className: 'flex-1',
    readonly: true,
  }
  let textColor = 'text-blue-500',
    bgcolors = 'bg-gray-200'
  const ImIcon = im?.Icon
  return (
    <VmView className="flex items-center">
      <VmView
        className={classNames(
          im?.className ? im.className : '',
          'flex flex-nowrap flex-col p-2 justify-between'
        )}
      >
        <VmText className={classNames(`text-xs`, textColor)}>
          {im?.title || ''}
        </VmText>
        <VmView
          className={classNames(
            im?.classNameContent ? im.classNameContent : '',
            'relative flex flex-row mt-1'
          )}
        >
          <VmView
            className={classNames(
              im?.classNameWinput ? im.classNameWinput : '',
              `relative flex flex-row h-fit w-[-webkit-fill-available] ${bgcolors} rounded-md`
            )}
          >
            <VmInput
              maxLength={im?.maxLength}
              typeInput={im?.typeInput}
              disabled={im?.disabled}
              readonly={im?.readonly}
              label={im.label}
              className={classNames(
                im?.typeInput === 'textarea' ? 'h-24 pt-3' : 'h-12',
                im?.usebtnremove ? '!pr-12' : '',
                im?.classNameInput ? im.classNameInput : '',
                'transparent-items center-items bg-transparent w-full rounded-l-none border-none'
              )}
              placeholder={im?.placeholder || ''}
              // key={data[im.field] || ''}
              value={inputValue}
            />
          </VmView>
        </VmView>
      </VmView>
      {/* <InputType1
        im={im}
        key={'abcdefghij123'}
        data={{}}
        ImIcon={ImIcon}
        bgcolors={bgcolors}
        textColor={textColor}

        // imInputRef={imInputRef}
        // handleValue={handleValue}
        // onChangeCapture={onChangeCapture}
        // lstAdditionalButtonsConfig={lstAdditionalButtonsConfig}
      /> */}
      <VmButton onClick={onClick} className={'mt-5'}>
        Cari
      </VmButton>
    </VmView>
  )
}

export default function AddPasien({
  lstinput = [],
  labelBackButton = 'modal-back-button',
  ...props
}) {
  const modalRef = useRef(),
    bottomSheetRef = useRef()
  const dctid = parseInt(useParams().id)
  const [loading, setloading] = useState(false)
  const [data, setdata] = useState({
    pasjk: genderlist[0].title,
    passtatusnikah: statusmeriedlist[0].title,
    paskota: '',
    pasalamat: '',
    pasgoldarah: '',
    paslat: '',
    paslng: '',
    pasid_satusehat: '',
  })
  const [propsalertform, setpropsalertform] = useState(defaultpropsalertform)
  const [propsbottomsheet, setpropsbottomsheet] = useState(
    defaultpropsbottomsheet
  )

  const getIdPasienSatuSehat = _ => {
    const {pastgllahir, pasnama, pasnoktp} = data

    if (!pastgllahir || !pasnama || !pasnoktp) {
      setpropsalertform({
        ...propsalertform,
        type: 4,
        visible: true,
        children: <>Harap isi Nama, NIK & Tanggal Lahir</>,
        onConfirm: closeAlert,
        onClose: closeAlert,
        onCancel: closeAlert,
      })
      return
    }
    setloading(true)
    return axios
      .post(
        `${process.env.REACT_APP_BASE_URL_SATUSEHAT}/satusehat-pasien/cari-id-satusehat-v2`,
        QueryString.stringify({
          reg: 'dbdok',
          a: 'dk13',
          kl_id: '19',
          userid: dctid,
          pasnoktp,
          pasnama,
          pastgllahir,
        })
      )
      .then(({data: result}) => {
        // Berhasil Get Data
        if (result.status == 1) {
          if (result.data.total > 0) {
            setdata({
              ...data,
              pasid_satusehat: result.data.entry[0].resource.id,
            })
          } else {
            setpropsalertform({
              ...propsalertform,
              type: 4,
              visible: true,
              children: (
                <>
                  {'Pasien dengan NIK ' +
                    pasnoktp +
                    ', Nama ' +
                    pasnama +
                    ', Tanggal Lahir ' +
                    moment(pastgllahir).format('YYYY-MM-DD') +
                    ' tidak ditemukan di Satu Sehat'}
                </>
              ),
              onConfirm: closeAlert,
              onClose: closeAlert,
              onCancel: closeAlert,
            })
            setdata({
              ...data,
              pasid_satusehat: '',
            })
          }
        } else {
          setdata({
            ...data,
            pasid_satusehat: '',
          })
          setpropsalertform({
            ...propsalertform,
            type: 4,
            visible: true,
            children: <>Gagal menghubungkan ke SatuSehat!</>,
            onConfirm: closeAlert,
            onClose: closeAlert,
            onCancel: closeAlert,
          })
        }
      })
      .catch(e => {
        setpropsalertform({
          ...propsalertform,
          type: 4,
          visible: true,
          children: <>Terjadi kesalahan saat get ID SatuSehat.!</>,
          onConfirm: closeAlert,
          onClose: closeAlert,
          onCancel: closeAlert,
        })
        // this.alert?.show({message: 'Terjadi kesalahan saat get ID SatuSehat.'})
        console.warn(
          'Terjadi Kesalahan saat get ID Pasien SatuSehat.',
          e.response?.data ?? e.message
        )
      })
      .finally(() => {
        // this.setState({gettingId: !true})
        setloading(!true)
      })
  }

  lstinput[5] = {
    type: -1,
    Component: (
      <InputIdSatusehat
        key="abcdefg123"
        inputValue={data.pasid_satusehat}
        onClick={getIdPasienSatuSehat}
      />
    ),
  }

  const onSavePasien = () => {
    // Validasi data dulu!
    if (isEmpty(data?.pasnama)) {
      return setpropsalertform({
        ...propsalertform,
        type: 4,
        visible: true,
        children: <>Nama Pasien tidak boleh kosong!</>,
        onConfirm: closeAlert,
        onClose: closeAlert,
        onCancel: closeAlert,
      })
    }
    if (isEmpty(data?.pasalamat)) {
      return setpropsalertform({
        ...propsalertform,
        type: 4,
        visible: true,
        children: <>Alamat Pasien tidak boleh kosong!</>,
        onConfirm: closeAlert,
        onClose: closeAlert,
        onCancel: closeAlert,
      })
    }
    if (isEmpty(data?.pastelp)) {
      return setpropsalertform({
        ...propsalertform,
        type: 4,
        visible: true,
        children: <>No. Wa tidak boleh kosong!</>,
        onConfirm: closeAlert,
        onClose: closeAlert,
        onCancel: closeAlert,
      })
    }
    if (isEmpty(data?.pasgoldarah)) {
      return setpropsalertform({
        ...propsalertform,
        type: 4,
        visible: true,
        children: <>Golongan darah tidak boleh kosong!</>,
        onConfirm: closeAlert,
        onClose: closeAlert,
        onCancel: closeAlert,
      })
    }
    setpropsalertform({
      ...propsalertform,
      type: 3,
      visible: true,
      children: (
        <>
          Pastikan data yang anda masukkan sudah benar
          <br />
          Yakin untuk menyimpan data?
        </>
      ),
      onConfirm: () => {
        setloading(true)
        mAddPasien({input: {data, uid: dctid}})
          .then(th => {
            if (th?.data?.status === 1) {
              const closeSuccess = () => {
                closeAlert()
                if (isFunction(props?.close)) {
                  props?.close()
                }
              }
              return setpropsalertform({
                ...propsalertform,
                type: 1,
                visible: true,
                children: <>Berhasil menyimpan pasien.</>,
                onConfirm: closeSuccess,
                onClose: closeSuccess,
                onCancel: closeSuccess,
              })
            } else {
              return setpropsalertform({
                ...propsalertform,
                type: 2,
                visible: true,
                children: (
                  <>
                    Gagal menyimpan pasien!
                    <br />
                    Silakan hubungi <b>Admin</b> jika masih terkendala.
                  </>
                ),
                onConfirm: closeAlert,
                onClose: closeAlert,
                onCancel: closeAlert,
              })
            }
          })
          .catch(ca => {
            return setpropsalertform({
              ...propsalertform,
              type: 2,
              visible: true,
              children: (
                <>
                  [C0] Gagal menyimpan pasien!
                  <br />
                  Silakan hubungi <b>Admin</b> jika masih terkendala.
                </>
              ),
              onConfirm: closeAlert,
              onClose: closeAlert,
              onCancel: closeAlert,
            })
          })
          .finally(fi => {
            setloading(false)
          })
      },
      onClose: closeAlert,
      onCancel: closeAlert,
    })
  }
  const closeAlert = () => setpropsalertform(defaultpropsalertform)
  const closeBs = () => setpropsbottomsheet(defaultpropsbottomsheet)
  const onClick = e => {
    // onClick
    try {
      const {ariaLabel} = e?.target
      if (ariaLabel === 'modal-add-pasien') {
        return onSavePasien()
      }
      if (`${ariaLabel}`?.match(/^input-/i)) {
        const field = `${ariaLabel}`?.replace(/^input-/i, '')
        if (field === 'pasjk') {
          const children = (
            <VmView className="px-4 pb-2">
              <VmText className="text-center font-bold uppercase pb-4">
                Jenis Kelamin
              </VmText>
              {genderlist.map(({type, title}, index) => {
                const pasjk = data?.pasjk || ''
                const active =
                  title?.toLowerCase() === `${pasjk}`?.toLowerCase()
                return (
                  <VmView
                    className={classNames(
                      'border rounded-xl text-center mb-2 py-1 font-semibold',
                      active
                        ? 'bg-blue1-payment text-white'
                        : 'text-blue1-payment bg-white'
                    )}
                    onClick={e => {
                      closeBs()
                      data['pasjk'] = title
                      setdata({...data})
                    }}
                    key={`pasjk-${type} + ${index}`}
                  >
                    {title}
                  </VmView>
                )
              })}
            </VmView>
          )
          return setpropsbottomsheet({
            ...propsbottomsheet,
            open: true,
            onClick: closeBs,
            children,
          })
        }
        if (field === 'passtatusnikah') {
          const children = (
            <VmView className="px-4 pb-2">
              <VmText className="text-center font-bold uppercase pb-4">
                Setatus Perkawinan
              </VmText>
              {statusmeriedlist.map(({type, title}, index) => {
                const passtatusnikah = data?.passtatusnikah || ''
                const active =
                  title?.toLowerCase() === `${passtatusnikah}`?.toLowerCase()
                return (
                  <VmView
                    className={classNames(
                      'border rounded-xl text-center mb-2 py-1 font-semibold',
                      active
                        ? 'bg-blue1-payment text-white'
                        : 'text-blue1-payment bg-white'
                    )}
                    onClick={e => {
                      closeBs()
                      data['passtatusnikah'] = title
                      setdata({...data})
                    }}
                    key={`passtatusnikah-${type} + ${index}`}
                  >
                    {title}
                  </VmView>
                )
              })}
            </VmView>
          )
          return setpropsbottomsheet({
            ...propsbottomsheet,
            open: true,
            onClick: closeBs,
            children,
          })
        }
      }
    } catch (error) {}
  }
  const onChange = e => {
    // onChange
    try {
      const {ariaLabel, value} = e?.target
      if (`${ariaLabel}`?.match(/^input-/i)) {
        const field = `${ariaLabel}`?.replace(/^input-/i, '')
        var newvalue = value
        if (field === 'pastelp') {
          newvalue = newvalue.replace(/[^\d.-]+/g, '')
          if (isNaN(newvalue)) {
            newvalue = ''
          }
        }
        if (field === 'pasnokk') {
          newvalue = newvalue.replace(/[^\d.-]+/g, '')
          if (isNaN(newvalue)) {
            newvalue = ''
          }
        }
        if (field === 'pasnoktp') {
          newvalue = newvalue.replace(/[^\d.-]+/g, '')
          if (isNaN(newvalue)) {
            newvalue = ''
          }
        }
        if (field === 'pasgoldarah') {
          if (isEmpty(newvalue)) {
            newvalue = ''
            e.target.value = ''
          } else {
            if (newvalue.match(/^(A|B|AB|O|A[+-]|B[+-]|AB[+-]|O[+-])$/i)) {
              newvalue = `${newvalue}`.toUpperCase()
            } else {
              newvalue = data[field]
              e.target.value = newvalue
              return setpropsalertform({
                ...propsalertform,
                type: 4,
                visible: true,
                children: <>Golongan darah tidak valid!</>,
                onConfirm: closeAlert,
                onClose: closeAlert,
                onCancel: closeAlert,
              })
            }
          }
        }
        if (field === 'pasmap') {
          var {lat = 0, lng = 0} = JSON.parse(newvalue)
          data['paslat'] = lat
          data['paslng'] = lng
        }
        e.target.value = newvalue
        data[field] = newvalue
        if (field === 'pastgllahir') {
          setdata({...data})
        }
        return
      }
    } catch (error) {}
  }
  useAddEventListener({eventName: 'click', handler: onClick})
  useAddEventListener({eventName: 'input', handler: onChange})
  const onBackPress = () => {
    setpropsalertform({
      ...propsalertform,
      type: 3,
      visible: true,
      children: 'Apakah anda yakin akan keluar menu ini?',
      onConfirm: () => {
        if (isFunction(props?.close)) {
          props?.close()
        }
      },
      onClose: () => setpropsalertform(defaultpropsalertform),
      onCancel: () => setpropsalertform(defaultpropsalertform),
    })
  }
  return (
    <VmView className="flex flex-col absolute inset-0 bg-blue4-payment px-5 pt-5">
      {/* Headers */}
      <VmHeader
        className="mb-5"
        HeaderMode="!text-black2-payment"
        HeaderButtonVersion={0}
        HeaderName="Menu"
        HeaderType={'Pembuatan Resep'}
        onBackPress={onBackPress}
        labelBackButton={labelBackButton}
      />
      <VmView className="flex flex-col h-[-webkit-fill-available] bg-white rounded-2xl p-4 mb-36">
        <TemplateListInputItems
          colors="text-black2-payment"
          bgcolors="!bg-gray0-payment"
          data={data}
          lstinput={lstinput}
          key={`lst-inpt-add-pasien`}
          scrollable={true}
        />
      </VmView>

      <Footer
        title="Tambah Pasien"
        label="modal-add-pasien"
        backButton
        backButtonTitle="Batal"
        backHandler={onBackPress}
      />

      {/* Bottom Sheet */}
      <BottomSheet ref={bottomSheetRef} {...propsbottomsheet} />
      {/* Modal */}
      <VmModal ref={modalRef} />
      {/* AlertForm */}
      <AlertForm {...propsalertform} />
      {/* Loading Full Screen */}
      <ProgressFullScreen visible={loading} />
    </VmView>
  )
}
