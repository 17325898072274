import {VmButton, VmModal, VmText, VmView} from 'components'
import {ProgressFullScreen, VmHeader} from 'molecules'
import React, {Fragment, useEffect, useMemo, useRef, useState} from 'react'
import Searchbar from './Searchbar'
import CardItem from './CardItem'
import Footer from './Footer'
import {BottomSheet} from 'react-spring-bottom-sheet'
import useAddEventListener from 'utils/useAddEventListener'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {defaultpropsbottomsheet} from 'entries/bottomsheet'
import classNames from 'classnames'
import {
  addPasien,
  inputCardCustomer,
  inputRcpDocWh,
  inputVisiting,
  lstAdditionalButtonsConfig,
} from './entries'
import {TemplateListInputItems} from 'template'
import AlertForm from 'template/Form/AlertForm'
import SearchMitra from './SearchMitra'
import SearchPasien from './SearchPasien'
import SearchObat from './SearchObat'
import SearchRacikan from './SearchRacikan'
import SearchResep from './SearchResep'
import AddPasien from './AddPasien'
import {defaultpropsalertform} from 'entries/alertform'
import {eventTrigerInput, isFunction} from 'utils'
import {getElement} from 'utils/get-element'
import {
  mCreateNewResep,
  mDetailVisit,
  mGetFaktur,
  mGetPasien,
  mIdentitasDokter,
  mMitraGetGudangNama,
  mSatuanObat,
} from './api'
import {getValue} from 'utils/datausr'
import {
  getLclStorage,
  removeLclStorage,
  setLclStorage,
} from 'utils/localstorage'
import {generateFlags, useCarts} from './utils'
import {
  CreatePenjualanResep,
  CreatePjData,
  CreatePjItemCarts,
  DataObat,
} from './classes'
import DetailRacikan from './DetailRacikan'
import DetailObat from './DetailObat'
import {useQuery} from 'utils/useQuery'

export default function PenjualanObatResep() {
  const navigate = useNavigate(),
    location = useLocation(),
    textColor = 'text-blue-500',
    bgcolors = 'bg-gray-200',
    fieldkey = ['obatid', 'sodid'],
    showlog = false,
    modalRef = useRef()
  const optcarts = {
    name: 'pj-resep-carts',
    flags: 'flagcart',
    flagstok: 'obatid',
  }
  const opt = {type: 2}
  const olddatadoc = getValue()
  const query = useQuery()
  const pid = query.get('pid')
  const kid = query.get('kid')
  const dctid = parseInt(useParams().id)
  const [triggerModal, settriggerModal] = useState(
    location.state?.modalSearchRacikan || false
  )
  const [propsalertform, setpropsalertform] = useState(defaultpropsalertform)
  const [loading, setloading] = useState(true)
  const [triggerloading, settriggerloading] = useState(true)
  const [data, setdata] = useState(() => {
    const storeddata = getLclStorage('data', opt)
    return storeddata
      ? JSON.parse(storeddata)
      : {
          'doct-name': olddatadoc?.nama_lengkap || '-',
        }
  })
  const [keyword, setkeyword] = useState('')
  const [collapse, setcollapse] = useState(false)
  const [propsbottomsheet, setpropsbottomsheet] = useState(
    defaultpropsbottomsheet
  )

  const {cart, carts, cartstoks, edititemcarts, removetocarts} =
    useCarts(optcarts)

  useEffect(() => {
    setLclStorage('data', JSON.stringify(data), opt)
    if (triggerModal) {
      settriggerModal(false)
      modalRef.current.open(searchRacikanModal())
    }
  }, [data])

  const searchRacikanModal = () => {
    return (
      <SearchRacikan
        fieldkey={fieldkey}
        optcarts={optcarts}
        datamitra={data.aptkldata}
        datapasien={data.pasiendata}
        onSelectItem={onSelectItem}
        close={defaultModalClose}
      />
    )
  }

  const defaultModalClose = (type = '') => {
    if (type == 'search-obat') setcollapse(true)
    modalRef.current?.close()
  }

  const closeBs = () => setpropsbottomsheet(defaultpropsbottomsheet)
  const closeAlert = () => setpropsalertform(defaultpropsalertform)
  const onSelectItem = (item = {}, type = '', handler = () => {}) => {
    // onSelectItem
    if (showlog) console.log('item, type', item, type)
    if (type === 'pasien') {
      const newpasien = {
        ...data,
        'pasien-name': item?.title || '-',
        pasiendata: item,
      }
      if (item?.kid) {
        newpasien['kid'] = item?.kid
        newpasien['kunno'] = item?.kunno
      } else {
        newpasien['kid'] = null
        newpasien['kunno'] = null
      }
      setdata(newpasien)
      return handler()
    }
    if (type === 'mitra') {
      setloading(true)
      return mMitraGetGudangNama({
        input: {
          a: item?.defaultdata?.appid_apotek,
          id: item?.defaultdata?.spdgudid,
          reg: item?.defaultdata?.regid_apotek,
        },
      })
        .then(({data: resdata = {}}) => {
          if (resdata?.status === 1) {
            setdata({
              ...data,
              'wh-name': resdata?.data,
              'aptkl-name': item?.title || '-',
              aptkldata: item,
            })
            handler()
          } else {
            setdata({
              ...data,
              'wh-name': '',
              'aptkl-name': '',
              aptkldata: null,
            })
            return setpropsalertform({
              ...propsalertform,
              type: 4,
              visible: true,
              children:
                resdata?.message ||
                'Gagal mengambil informasi gudang Apotek/Klinik!',
              onConfirm: closeAlert,
              onClose: closeAlert,
            })
          }
          if (showlog) console.log('resdata', resdata)
        })
        .finally(() => {
          setloading(false)
        })
    }
  }
  const onClick = e => {
    // onClick
    try {
      const {ariaLabel} = e?.target
      if (showlog) console.error('ariaLabel', ariaLabel)
      if (e?.target?.dataset?.rsbsBackdrop === 'true') {
        // onClick backdrop Bottom Sheet
        return closeBs()
      }
      if (ariaLabel === 'collapse-hdr') {
        return setcollapse(!collapse)
      }
      if (ariaLabel === 'add-new-resep') {
        if (carts?.length === 0) {
          return setpropsalertform({
            ...propsalertform,
            type: 4,
            title: 'Peringatan',
            visible: true,
            children: <>Keranjang anda masih kosong!</>,
            onConfirm: closeAlert,
            onClose: closeAlert,
            onCancel: closeAlert,
          })
        }
        if (data['rcpno-name']?.length === 0) {
          return setpropsalertform({
            ...propsalertform,
            type: 4,
            title: 'Peringatan',
            visible: true,
            children: <>No. Resep tidak boleh kosong!</>,
            onConfirm: closeAlert,
            onClose: closeAlert,
            onCancel: closeAlert,
          })
        }
        if (!data?.aptkldata?.defaultdata?.spdgudid) {
          return setpropsalertform({
            ...propsalertform,
            type: 4,
            title: 'Peringatan',
            visible: true,
            children: <>Gudang tidak valid!</>,
            onConfirm: closeAlert,
            onClose: closeAlert,
            onCancel: closeAlert,
          })
        }
        const onConfirm = () => {
          Promise.all([closeAlert(), setloading(true)]).finally(() => {
            const newdata = new CreatePjData({...data, uid: dctid})
            var newcarts = []
            for (let i = 0; i < carts.length; i++) {
              const im = carts[i]
              newcarts.push(
                new CreatePjItemCarts({
                  ...im.defaultdata,
                  ...im,
                  pjdcatatan: im?.defaultdata?.pjdcatatan || '',
                  gudid: data?.aptkldata?.defaultdata?.spdgudid,
                })
              )
            }
            const newpjresep = new CreatePenjualanResep({
              uid: dctid,
              data: newdata,
              carts: newcarts,
            })
            return mCreateNewResep({input: newpjresep})
              .then(({data: resdata = {}}) => {
                if (resdata?.status === 1) {
                  const onClose = () => {
                    resetData()
                    closeAlert()
                    navigate(
                      `/prd-penjualan-obat-resep/cetakbarcode/${dctid}-${resdata.pjid}`,
                      {replace: !!kid || !!pid || false}
                    )
                  }
                  setpropsalertform({
                    ...propsalertform,
                    type: 1,
                    visible: true,
                    children: resdata?.message || 'Berhasil menyimpan data.',
                    onConfirm: onClose,
                    onClose: onClose,
                  })
                } else {
                  setpropsalertform({
                    ...propsalertform,
                    type: 4,
                    visible: true,
                    children: resdata?.message || 'Gagal menyimpan data!',
                    onConfirm: closeAlert,
                    onClose: closeAlert,
                  })
                }
                if (showlog) console.log('onCreateNewResep:data', resdata)
              })
              .catch(ch => {
                setpropsalertform({
                  ...propsalertform,
                  type: 4,
                  visible: true,
                  children: '[C0] Gagal menyimpan data!',
                  onConfirm: closeAlert,
                  onClose: closeAlert,
                })
                if (showlog) console.log('onCreateNewResep:ch', ch)
              })
              .finally(() => {
                setloading(false)
                if (showlog) console.log('onCreateNewResep:final')
              })
          })
        }
        return setpropsalertform({
          ...propsalertform,
          type: 3,
          title: 'Peringatan',
          visible: true,
          children: (
            <>
              Pastikan data yang anda masukkan <b>Sudah Benar</b>.
              <br />
              Yakin ingin melanjutkan?
            </>
          ),
          onConfirm,
          onClose: closeAlert,
          onCancel: closeAlert,
        })
      }
      if (ariaLabel === 'add-new-racikan') {
        navigate('/racikan/create/' + dctid, {state: {...data, dctid}})
      }
      if (`${ariaLabel}`?.match(/^showsatuan-k-##/i)) {
        const idx = `${ariaLabel}`.split('-##-')[2]
        const item = cart[idx]
        return onFetchSatuan(
          {
            a: data?.aptkldata?.defaultdata?.app_id,
            id: item?.defaultdata?.obatid,
            reg: data?.aptkldata?.defaultdata?.regid_apotek,
          },
          idx
        )
      }
      if (`${ariaLabel}`?.match(/^edit-item-##/i)) {
        // Open Bottom Sheet Edit Obat
        const idx = `${ariaLabel}`.split('-##-')[2]
        const item = cart[idx]
        return modalEditItem(item, idx, ariaLabel)
      }
      if (`${ariaLabel}`?.match(/^detail-mitra-racikan-##/i)) {
        // Open Bottom Sheet Edit Obat Racikan
        const idx = `${ariaLabel}`.split('-##-')[2]
        const item = cart[idx]
        return modalEditRacikan(item, idx, ariaLabel)
      }
      if (`${ariaLabel}`?.match(/^delete-item-##/i)) {
        const idx = `${ariaLabel}`.split('-##-')[2]
        const item = cart[idx]
        return setpropsalertform({
          ...propsalertform,
          type: 2,
          title: 'Peringatan',
          visible: true,
          children: (
            <>
              Apakah anda yakin ingin menghapus item ini (<b>{item?.name}</b>)?
            </>
          ),
          onConfirm: () => {
            removetocarts(item)
            if (`${item?.jenis}`.toLowerCase() === 'racikan') {
              carts.map(im => {
                if (item?.flaxR === im?.flaxR) {
                  removetocarts(im)
                }
              })
            } else {
              if (`${item?.flaxR}`.toLowerCase()?.includes('racikan')) {
                const isEmptyRck =
                  carts?.filter(
                    fl =>
                      `${fl?.jenis}`.toLowerCase() !== 'racikan' &&
                      item?.flaxR === fl?.flaxR &&
                      item?.flagcart !== fl?.flagcart
                  )?.length === 0
                if (isEmptyRck) {
                  carts.map(im => {
                    if (item?.flaxR === im?.flaxR) {
                      removetocarts(im)
                    }
                  })
                  defaultModalClose()
                }
              }
            }
          },
          onClose: closeAlert,
          onCancel: closeAlert,
        })
      }
      if (`${ariaLabel}`?.match(/^plus-item-##/i)) {
        const idx = `${ariaLabel}`.split('-##-')[2]
        const item = cart[idx]
        var newitem = item
        const {sisastok, stok, qtyincart} = cartstoks[item[optcarts.flagstok]]
        const konversi = parseFloat(newitem?.defaultdata?.sodhasilkonversi || 1)
        if (showlog) {
          console.log('sisastok, stok, qtyincart', sisastok, stok, qtyincart)
        }
        if (sisastok - 1 * (konversi ? konversi : 1) < 0) {
          return setpropsalertform({
            ...propsalertform,
            type: 4,
            visible: true,
            children: (
              <>
                Stok obat <b>{item?.name}</b> tidak mencukupi, sisa stok ={' '}
                <b>
                  {stok} {item?.satuan1}
                </b>
              </>
            ),
            onConfirm: closeAlert,
            onClose: closeAlert,
          })
        }
        newitem = {
          ...newitem,
          qty: parseInt(newitem?.qty) + 1,
        }
        edititemcarts(newitem, 'flagcart')
        return
      }
      if (`${ariaLabel}`?.match(/^minus-item-##/i)) {
        const idx = `${ariaLabel}`.split('-##-')[2]
        const item = cart[idx]
        var newitem = item
        newitem = {
          ...newitem,
          qty: parseInt(newitem?.qty) - 1,
        }
        edititemcarts(newitem, 'flagcart')
        return
      }
      if (ariaLabel === 'reset-search-obat') {
        const finsInputEl = getElement({
          tag: 'input',
          by: 'ariaLabel',
          equal: 'input-search-obat',
        })
        setkeyword('')
        return eventTrigerInput(finsInputEl, '')
      }
      if (carts?.length > 0) {
        if (
          ['remove-input-pasien-name', 'remove-input-aptkl-name'].includes(
            ariaLabel
          )
        ) {
          const finsInputEl = getElement({
            tag: 'input',
            by: 'ariaLabel',
            equal: `${ariaLabel}`.replace('remove-', ''),
          })
          finsInputEl.value =
            data[`${ariaLabel}`.replace('remove-input-', '')] || ''
          return setpropsalertform({
            ...propsalertform,
            type: 4,
            visible: true,
            children: 'Tidak dapat dihapus!',
            onConfirm: closeAlert,
            onClose: closeAlert,
          })
        }
        if (
          ['search-input-aptkl-name', 'input-aptkl-name'].includes(ariaLabel)
        ) {
          return setpropsalertform({
            ...propsalertform,
            type: 4,
            visible: true,
            children: 'Tidak dapat diubah ketika ada obat yang dipilih!',
            onConfirm: closeAlert,
            onClose: closeAlert,
          })
        }
      }
      if (ariaLabel === 'remove-input-rcpno-name') {
        return setdata({...data, 'rcpno-name': ''})
      }
      if (ariaLabel === 'remove-input-pasien-name') {
        return setdata({
          ...data,
          'pasien-name': '',
          pasiendata: null,
          kid: null,
          kunno: null,
        })
      }
      if (ariaLabel === 'remove-input-aptkl-name') {
        return setdata({
          ...data,
          'aptkl-name': '',
          aptkldata: null,
          'wh-name': null,
        })
      }
      if (['search-input-aptkl-name', 'input-aptkl-name'].includes(ariaLabel)) {
        // Open Modal Pencarian Mitra
        const modalPenjualan = (
          <SearchMitra onSelectItem={onSelectItem} close={defaultModalClose} />
        )
        return modalRef.current.open(modalPenjualan)
      }
      if (
        [
          'add-pasien',
          'search-input-pasien-name',
          'input-pasien-name',
        ].includes(ariaLabel)
      ) {
        // Open Modal Pencarian Pasien
        const modalPenjualan = (
          <SearchPasien onSelectItem={onSelectItem} close={defaultModalClose} />
        )
        if (ariaLabel === 'add-pasien') {
          return modalRef.current?.open(
            <AddPasien
              lstinput={addPasien.lstinput}
              close={() => modalRef.current?.open(modalPenjualan)}
            />
          )
        }
        return modalRef.current.open(modalPenjualan)
      }
      if (
        [
          'btn-search-obat',
          'search-racikan',
          'search-resep',
          'add-resep',
        ].includes(ariaLabel) &&
        (!data?.aptkldata || !data?.pasiendata)
      ) {
        return setpropsalertform({
          ...propsalertform,
          type: 4,
          visible: true,
          children: 'Harap pilih Apotek/Klinik dan Pasien terlebih dahulu!',
          onConfirm: closeAlert,
          onClose: closeAlert,
        })
      }
      if (ariaLabel === 'btn-search-obat') {
        // Open Modal Pencarian Obat
        const modalPenjualan = (
          <SearchObat
            keyword={keyword}
            fieldkey={fieldkey}
            optcarts={optcarts}
            datamitra={data.aptkldata}
            datapasien={data.pasiendata}
            onSelectItem={onSelectItem}
            close={() => defaultModalClose('search-obat')}
          />
        )
        return modalRef.current.open(modalPenjualan)
      }
      if (ariaLabel === 'search-racikan') {
        // Open Modal Pencarian racikan
        return modalRef.current.open(searchRacikanModal())
      }
      if (ariaLabel === 'search-resep') {
        // Open Modal Pencarian resep
        const modalPenjualan = (
          <SearchResep
            fieldkey={fieldkey}
            optcarts={optcarts}
            datamitra={data.aptkldata}
            datapasien={data.pasiendata}
            onSelectItem={onSelectItem}
            close={defaultModalClose}
          />
        )
        return modalRef.current.open(modalPenjualan)
      }
      if (ariaLabel === 'reset-carts') {
        return setpropsalertform({
          ...propsalertform,
          type: 2,
          title: 'Peringatan',
          visible: true,
          children: <>Apakah anda yakin ingin mereset items?</>,
          onConfirm: claerData,
          onClose: closeAlert,
          onCancel: closeAlert,
        })
      }
    } catch (error) {}
  }
  const onChange = e => {
    // onChange
    try {
      const {ariaLabel, value} = e?.target
      if (showlog) {
        console.log('ariaLabel', ariaLabel, value)
      }
      if (ariaLabel === 'input-search-obat') {
        return setkeyword(value)
      }
      if (`${ariaLabel}`?.match(/^input-qty-##/i)) {
        const idx = `${ariaLabel}`.split('-##-')[2]
        const item = cart[idx]
        var newqty = parseInt(value || 0)
        if (newqty > 0) {
          var newitem = item
          if (isNaN(newqty)) {
            newqty = 0
          }
          const {sisastok, stok, qtyincart} = cartstoks[item[optcarts.flagstok]]
          const konversi = parseFloat(
            newitem?.defaultdata?.sodhasilkonversi || 1
          )
          if (
            sisastok +
              item.qty * (konversi ? konversi : 1) -
              newqty * (konversi ? konversi : 1) <
            0
          ) {
            newitem = {
              ...newitem,
              qty: item.qty,
            }
            e.target.value = newitem.qty
            edititemcarts(newitem, 'flagcart')
            return setpropsalertform({
              ...propsalertform,
              type: 4,
              visible: true,
              children: (
                <>
                  Stok obat <b>{item?.name}</b> tidak mencukupi, sisa stok ={' '}
                  <b>
                    {stok} {item?.satuan1}
                  </b>
                </>
              ),
              onConfirm: closeAlert,
              onClose: closeAlert,
            })
          }
          e.target.value = newqty
        } else {
          e.target.value = ''
        }
        return
      }
    } catch (error) {
      if (showlog) {
        console.log('onChangeError', error)
      }
    }
  }
  const onKeypress = e => {
    // onKeypress
    try {
      const {ariaLabel, value} = e?.target
      if (showlog) {
        console.log('ariaLabel', ariaLabel, e)
      }
      if (ariaLabel === 'input-search-obat' && e?.keyCode === 13) {
        var newtimeout = null
        newtimeout = setTimeout(() => {
          const finsInputEl = getElement({
            tag: 'button',
            by: 'ariaLabel',
            equal: 'btn-search-obat',
          })
          finsInputEl?.click()
          clearTimeout(newtimeout)
        }, 100)
        return
      }
    } catch (error) {
      if (showlog) {
        console.log('onKeypressError', error)
      }
    }
  }
  const onBlur = e => {
    // onBlur
    try {
      const {ariaLabel, value} = e?.target,
        lsttriggre = ['input-rcpno-name']
      if (showlog) {
        console.error('ariaLabel', ariaLabel, value)
      }
      if (lsttriggre.includes(ariaLabel)) {
        settriggerloading(true)
      }
      if (ariaLabel.split('##')[0] == 'input-textarea-etiket') {
        const cartId = ariaLabel.split('##')[1]
        const currentCart = cart[cartId]

        edititemcarts(
          {
            ...currentCart,
            defaultdata: {
              ...currentCart?.defaultdata,
              pjdcatatan: value,
            },
          },
          'flagcart'
        )
      }
      if (`${ariaLabel}`?.match(/^input-qty-##/i)) {
        const idx = `${ariaLabel}`.split('-##-')[2]
        const item = cart[idx]
        var newqty = parseInt(value || 0)
        if (newqty > 0) {
          var newitem = item
          if (isNaN(newqty)) {
            newqty = 0
          }
          const {sisastok, stok, qtyincart} = cartstoks[item[optcarts.flagstok]]
          const konversi = parseFloat(
            newitem?.defaultdata?.sodhasilkonversi || 1
          )
          if (
            sisastok +
              item.qty * (konversi ? konversi : 1) -
              newqty * (konversi ? konversi : 1) <
            0
          ) {
            newitem = {
              ...newitem,
              qty: item.qty,
            }
            e.target.value = newitem.qty
            edititemcarts(newitem, 'flagcart')
            return setpropsalertform({
              ...propsalertform,
              type: 4,
              visible: true,
              children: (
                <>
                  Stok obat <b>{item?.name}</b> tidak mencukupi, sisa stok ={' '}
                  <b>
                    {stok} {item?.satuan1}
                  </b>
                </>
              ),
              onConfirm: closeAlert,
              onClose: closeAlert,
            })
          }
          newitem = {
            ...newitem,
            qty: newqty,
          }
          edititemcarts(newitem, 'flagcart')
          e.target.value = newqty
        } else {
          edititemcarts(item, 'flagcart')
          e.target.value = item.qty
        }
        return
      }
      if (`${ariaLabel}`?.match(/^input-qty-modal-obat-##/i)) {
        const splitters = `${ariaLabel}`.split('-##-')
        const idx = splitters[2]
        const item = cart[idx]
        const equal = `input-qty-##-${item?.index}-##-${idx}`
        const finsInputEl = getElement({
          tag: 'input',
          by: 'ariaLabel',
          equal,
        })
        return Promise.all([finsInputEl.focus()]).finally(() => {
          finsInputEl.blur()
          var newtimeout = null
          newtimeout = setTimeout(() => {
            e.target.value = finsInputEl.value
            clearTimeout(newtimeout)
          }, 100)
        })
      }
      if (`${ariaLabel}`?.match(/^input-rcpno-name$/i)) {
        return setdata({...data, 'rcpno-name': `${value}`.trim().toUpperCase()})
      }
    } catch (error) {
      if (showlog) {
        console.log('onBlur', error)
      }
    }
  }
  const onFetchSatuan = (input = {id: 0, reg: '', a: ''}, flagcart = '') => {
    setloading(true)
    mSatuanObat({
      input,
    })
      .then(({data: resdata = {}}) => {
        if (resdata?.data?.length > 0) {
          const list = resdata?.data
          const children = (
            <VmView className="px-4 pb-2">
              <VmText className="text-center font-bold uppercase pb-4">
                Satuan
              </VmText>
              {list?.length > 0 ? (
                list.map(
                  ({sodid: type, sonama: title, ...datasatuan}, index) => {
                    const sodid = cart[flagcart]?.defaultdata?.sodid || {}
                    const active =
                      type?.toLowerCase() === `${sodid}`?.toLowerCase()
                    return (
                      <VmView
                        className={classNames(
                          'border rounded-xl text-center mb-2 py-1 font-semibold',
                          active
                            ? 'bg-blue1-payment text-white'
                            : 'text-blue1-payment bg-white'
                        )}
                        onClick={e => {
                          closeBs(e)
                          var newitem = cart[flagcart]
                          const newflag = generateFlags(
                            {
                              ...newitem?.defaultdata,
                              sodid: type,
                              sonama: title,
                            },
                            fieldkey
                          )
                          if (!cart[newflag]) {
                            const {sisastok, stok, qtyincart} =
                              cartstoks[newitem[optcarts.flagstok]]
                            const konversi = parseFloat(
                              newitem?.sodhasilkonversi
                            )
                            const newkonversi = parseFloat(
                              datasatuan?.sodhasilkonversi
                            )
                            if (showlog) {
                              console.log(
                                'sisastok, stok,qtyincart',
                                sisastok,
                                stok,
                                qtyincart
                              )
                            }
                            if (
                              sisastok +
                                newitem.qty * (konversi ? konversi : 1) -
                                newitem.qty * (newkonversi ? newkonversi : 1) <
                              0
                            ) {
                              return setpropsalertform({
                                ...propsalertform,
                                type: 4,
                                visible: true,
                                children: (
                                  <>
                                    Stok obat <b>{newitem?.name}</b> tidak
                                    mencukupi, sisa stok ={' '}
                                    <b>
                                      {stok} {newitem?.satuan1}
                                    </b>
                                  </>
                                ),
                                onConfirm: closeAlert,
                                onClose: closeAlert,
                              })
                            }
                            newitem = {
                              ...newitem,
                              satuan: title,
                              defaultdata: {
                                ...newitem?.defaultdata,
                                ...datasatuan,
                                sodid: type,
                                sonama: title,
                              },
                              fieldkey,
                            }
                            edititemcarts(newitem, 'flagcart')
                          } else {
                            setpropsalertform({
                              ...propsalertform,
                              type: 4,
                              visible: true,
                              children: (
                                <>
                                  Obat <b>{newitem?.name}</b> dengan satuan{' '}
                                  <b>{newitem?.satuan}</b> sudah ada didalam
                                  keranjang!
                                </>
                              ),
                              onConfirm: closeAlert,
                              onClose: closeAlert,
                            })
                          }
                        }}
                        key={type + index}
                      >
                        {title}
                      </VmView>
                    )
                  }
                )
              ) : (
                <VmView
                  className={classNames(
                    'border rounded-xl text-center mb-2 py-1 font-semibold'
                  )}
                >
                  List kosong!
                </VmView>
              )}
            </VmView>
          )
          return setpropsbottomsheet({
            ...propsbottomsheet,
            open: true,
            onClick: closeBs,
            children,
          })
        }
        if (showlog) console.log('onFetchSatuan:data', resdata)
      })
      .catch(ch => {
        if (showlog) console.log('onFetchSatuan:ch', ch)
      })
      .finally(() => {
        setloading(false)
        if (showlog) console.log('onFetchSatuan:final')
      })
  }
  const onFetchVisit = (idata = {}) => {
    mDetailVisit({
      input: {
        uid: dctid,
        kid: idata?.kid,
        'show-error-log': `${showlog}`.toUpperCase(),
      },
    })
      .then(({data: resdata = {}}) => {
        if (resdata?.status === 1) {
          const item = resdata.data
          const fdata = {
            ...data,
            ...idata,
            'pasien-name': item?.title || '-',
            pasiendata: item,
          }
          if (item?.kid) {
            fdata['kid'] = item?.kid
            fdata['kunno'] = item?.kunno
          }
          setdata(fdata)
        }
        if (showlog) console.log('onFetchPasien:data', resdata)
      })
      .catch(ch => {
        if (showlog) console.log('onFetchPasien:ch', ch)
      })
      .finally(() => {
        if (showlog) console.log('onFetchPasien:final')
      })
  }
  const onFetchPasien = (idata = {}) => {
    mGetPasien({
      input: {id: pid, 'show-error-log': `${showlog}`.toUpperCase()},
    })
      .then(({data: resdata = {}}) => {
        if (resdata?.status === 1) {
          const item = resdata.data
          const fdata = {
            ...data,
            ...idata,
            'pasien-name': item?.title || '-',
            pasiendata: item,
            kid,
          }
          if (kid) {
            onFetchVisit(fdata)
          } else {
            setdata(fdata)
          }
        }
        if (showlog) console.log('onFetchPasien:data', resdata)
      })
      .catch(ch => {
        if (showlog) console.log('onFetchPasien:ch', ch)
      })
      .finally(() => {
        if (showlog) console.log('onFetchPasien:final')
      })
  }
  const onFetchFaktur = (idata = {}) => {
    mGetFaktur({
      input: {uid: dctid, 'show-error-log': `${showlog}`.toUpperCase()},
    })
      .then(({data: resdata = {}}) => {
        if (resdata?.status === 1) {
          const fdata = {
            ...data,
            ...idata,
            'rcpno-name': resdata?.data,
            nofaktur: resdata?.data,
          }
          if (pid) {
            // get pasien
            onFetchPasien(fdata)
          } else {
            setdata(fdata)
          }
        }
        if (showlog) console.log('onFetchFaktur:data', resdata)
      })
      .catch(ch => {
        if (showlog) console.log('onFetchFaktur:ch', ch)
      })
      .finally(() => {
        if (showlog) console.log('onFetchFaktur:final')
      })
  }
  const onFetchIdentitas = (count = 0) => {
    setloading(true)
    mIdentitasDokter({
      input: {id: dctid},
    })
      .then(resdata => {
        if (resdata?.status === 1) {
          const idata = {
            ...data,
            'doct-name': resdata?.data?.name,
            datadoct: resdata?.data,
          }
          onFetchFaktur(idata)
        } else {
          if (count > 10) {
            return setpropsalertform({
              ...propsalertform,
              type: 4,
              visible: true,
              children: (
                <>
                  Gagal mengambil informasi user!
                  <br />
                  Silakan hubungi <b>Admin</b> jika user anda masih terkendala.
                </>
              ),
              onConfirm: goBack,
              onClose: goBack,
              onCancel: goBack,
            })
          }
          setpropsalertform({
            ...propsalertform,
            type: 3,
            visible: true,
            children: (
              <>
                Gagal mengambil informasi user!
                <br />
                Apakah anda ingin mengulangi proses?
              </>
            ),
            onConfirm: () => onFetchIdentitas(count + 1),
            onClose: closeAlert,
            onCancel: goBack,
          })
        }
        if (showlog) console.log('onFetchIdentitas:data', resdata)
      })
      .catch(ch => {
        if (showlog) console.log('onFetchIdentitas:ch', ch)
      })
      .finally(() => {
        if (showlog) console.log('onFetchIdentitas:final')
        setloading(false)
      })
  }
  const modalEditItem = (item = {}, idx = '', label = '') => {
    return modalRef.current.open(
      <DetailObat
        optcarts={optcarts}
        label={label}
        data={item}
        idx={idx}
        key={`${JSON.stringify(item)}-detail-obat`}
        close={defaultModalClose}
      />
    )
  }
  const modalEditRacikan = (item = {}, idx = '', label = '') => {
    return modalRef.current.open(
      <DetailRacikan
        optcarts={optcarts}
        label={label}
        data={item}
        idx={idx}
        key={`${JSON.stringify(item)}-detail-racikan`}
        close={defaultModalClose}
      />
    )
  }
  const initialData = () => {
    onFetchIdentitas()
  }
  useEffect(() => {
    if (window?.ReactNativeWebView?.postMessage) {
      window.ReactNativeWebView.postMessage(`setDisableBackRN:`)
    }
    initialData()
    return () => {
      // console.log('first class')
      if (window?.ReactNativeWebView?.postMessage) {
        window.ReactNativeWebView.postMessage(`setEnableBackRN:`)
      }
    }
  }, [])
  useEffect(() => {
    settriggerloading(false)
    return () => {
      settriggerloading(false)
    }
  }, [triggerloading])
  const claerData = () => {
    removeLclStorage('data')
    removeLclStorage(optcarts.name)
    removeLclStorage(`${optcarts.name}-t`)
  }
  const resetData = () => {
    Promise.all([claerData()]).finally(() => {
      const removeBtnPaien = getElement({
        tag: 'div',
        by: 'ariaLabel',
        equal: 'remove-input-pasien-name',
      })
      const removeBtnAptkl = getElement({
        tag: 'div',
        by: 'ariaLabel',
        equal: 'remove-input-aptkl-name',
      })
      removeBtnPaien.click()
      removeBtnAptkl.click()
      onFetchFaktur(data)
    })
  }
  const goBack = () => {
    claerData()
    if (window?.ReactNativeWebView?.postMessage) {
      Promise.all([
        window.ReactNativeWebView.postMessage(`setEnableBackRN:`),
      ]).finally(() => {
        window.ReactNativeWebView.postMessage('onBackPress:')
      })
    } else {
      navigate(-1)
    }
  }
  const onBackPress = e => {
    setpropsalertform({
      ...propsalertform,
      type: 3,
      visible: true,
      children: 'Apakah anda yakin akan keluar menu ini?',
      onConfirm: goBack,
      onClose: closeAlert,
      onCancel: closeAlert,
    })
  }
  const onHardwareBackPress = event => {
    const {data} = event
    if (`${data}`?.match(/^onHardwareBackPress:/i)) {
      const rbsPortal = document.getElementsByTagName('reach-portal')
      if (rbsPortal[0]?.children[0]?.dataset?.rsbsState === 'open') {
        rbsPortal[0]?.children[0]?.children[0]?.click()
        return
      }
      const finsModalBackEl = getElement({
        tag: 'button',
        by: 'ariaLabel',
        equal: 'modal-back-button',
      })
      if (modalRef.current?.isOpen) {
        if (isFunction(finsModalBackEl?.click)) {
          return finsModalBackEl?.click()
        }
      }
      return onBackPress()
    }
  }
  useAddEventListener({
    eventName: 'message',
    eventRNName: 'hardwareBackPress',
    handler: onHardwareBackPress,
  })
  useAddEventListener({eventName: 'click', handler: onClick})
  useAddEventListener({eventName: 'input', handler: onChange})
  useAddEventListener({eventName: 'keypress', handler: onKeypress})
  useAddEventListener({eventName: 'focusout', handler: onBlur})

  return (
    <VmView className="flex flex-col-reverse justify-end min-h-screen bg-blue4-payment px-5">
      <VmView className="flex flex-col relative h-[-webkit-fill-available]">
        {/* Content */}
        <VmView className="relative overflow-y-scroll mb-auto h-[-webkit-fill-available] md:rounded-2xl">
          {carts.map((im = new DataObat({}), index) => {
            const isracikan = im?.flaxR?.includes('racikan')
            const ischildracikan =
              isracikan && `${im?.jenis}`.toLowerCase() !== 'racikan'
            if (ischildracikan) {
              return <Fragment key={`CardItem-${index}`} />
            }

            const lastIndex = carts.length - 1

            return (
              <CardItem
                key={`CardItem-${index}`}
                index={index}
                lastIndex={lastIndex}
                data={im}
                defaultEtiket={im?.defaultdata?.pjdcatatan}
                showEtiket={true}
                cardGap={false}
              />
            )
          })}
        </VmView>

        <VmView className="p-10" />
      </VmView>
      <VmView className="flex flex-col sticky top-0 left-5 right-5 bg-blue4-payment">
        {/* Headers */}
        <VmHeader
          className="sticky mb-5 pt-5"
          HeaderMode="!text-black2-payment"
          HeaderButtonVersion={0}
          HeaderName="Menu"
          HeaderType={'Pembuatan Resep'}
          onBackPress={onBackPress}
        />
        {/* Sales Information */}
        <VmView
          key={`collapse-hdr-${collapse}`}
          className={classNames(
            'flex flex-col bg-blue1-payment rounded-3xl mb-5',
            collapse ? 'py-5 md:py-4' : 'pb-3 pt-5 md:pt-4'
          )}
        >
          <VmView className="flex flex-row justify-between items-center px-5 md:px-4 text-sm text-white text-prevent-select">
            <VmView className="flex flex-row">
              <VmText className="mr-1.5">Informasi</VmText>
              {collapse && (
                <VmText className="hidden md:block">
                  {`${data?.['pasien-name'] ? '| ' : ''} ${
                    data?.['pasien-name'] || ''
                  } ${data?.['aptkl-name'] ? '| ' : ''} ${
                    data?.['aptkl-name'] || ''
                  }`}
                </VmText>
              )}
            </VmView>

            <VmView
              label={`collapse-hdr`}
              className="flex bg-yellow2-payment items-center rounded-full min-w-[30%] justify-center text-xs cursor-pointer md:hidden"
            >
              {collapse ? 'Buka' : 'Tutup'}
            </VmView>

            <VmButton
              label={`collapse-hdr`}
              hidering
              className="hidden !bg-yellow2-payment text-xs cursor-pointer md:block px-4 py-2"
            >
              {collapse ? 'Buka' : 'Tutup'}
            </VmButton>
          </VmView>
          {/* Patient | Mitra */}
          <TemplateListInputItems
            colors="text-white"
            bgcolors="!bg-white"
            className={classNames(
              collapse ? '!h-0 overflow-hidden z-[-1]' : `px-3`
            )}
            data={data}
            lstinput={inputCardCustomer.lstinput}
            lstAdditionalButtonsConfig={lstAdditionalButtonsConfig}
            key={`lst-inpt-cutmr-${collapse}`}
          />
          {/* No. Recipe | Doctor | Warehouse | Kunjungan? */}
          <TemplateListInputItems
            colors="text-white"
            bgcolors="!bg-white"
            className={classNames(
              collapse
                ? '!h-0 overflow-hidden z-[-1]'
                : `px-3 flex !flex-row overflow-x-scroll`
            )}
            data={data}
            lstinput={[
              ...inputRcpDocWh.lstinput,
              data?.kid ? inputVisiting : null,
            ]}
            key={`lst-inpt-inputRcpDocWh-${collapse}`}
          />
        </VmView>

        {/* section search */}
        <VmView className="md:flex md:items-start">
          <VmView className="w-full mr-4">
            <Searchbar keyword={keyword} label="search-obat" />
          </VmView>

          <VmView className="relative flex flex-row w-full mb-5">
            <VmButton
              hidering={true}
              className="mr-2 text-sm p-2 px-4 w-[-webkit-fill-available] !bg-blue1-payment !text-white md:h-[2.4rem]"
              label="search-racikan"
            >
              Cari Racikan
            </VmButton>
            <VmButton
              hidering={true}
              className="ml-2 text-sm p-2 px-4 w-[-webkit-fill-available] !bg-blue1-payment !text-white md:h-[2.4rem]"
              label="search-resep"
            >
              Cari Resep
            </VmButton>
          </VmView>
        </VmView>
      </VmView>
      {/* Footer */}
      <Footer label="add-new-resep" />
      {/* Reset Carts */}
      {carts?.length > 0 && (
        <VmView className="fixed left-5 bottom-5">
          <VmButton
            label={'reset-carts'}
            hidering={true}
            className={classNames(
              'flex justify-center items-center !flex-none text-sm p-2 px-4 !min-w-[45%] !bg-red5-payment !text-white'
            )}
          >
            Reset
          </VmButton>
        </VmView>
      )}
      {/* Bottom Sheet */}
      <BottomSheet {...propsbottomsheet} />
      {/* Modal */}
      <VmModal
        className="!inset-0"
        classNameContent="absolute !inset-0 !h-screen max-h-none !rounded-none"
        classNameBody="absolute !inset-0"
        ref={modalRef}
      />
      {/* AlertForm */}
      <AlertForm {...propsalertform} />
      {/* Loading Full Screen */}
      <ProgressFullScreen visible={loading || triggerloading} />
    </VmView>
  )
}
