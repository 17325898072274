import React, {useState, useEffect, useRef} from 'react'
import axios from 'axios'
import InfiniteScroll from 'react-infinite-scroller'
import {VmButton, VmSpinner, VmText, VmView} from 'components'
import {API7} from 'config/apiurl'
import QueryString from 'qs'
import Searchbar from './Searchbar'
import {useLocation, useNavigate} from 'react-router-dom'
import {VmHeader} from 'molecules'
import Footer from './Footer'
import {useCarts} from './utils'

const Etiket = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [hasMore, setHasMore] = useState(true)
  const [keyword, setkeyword] = useState('')

  const location = useLocation()
  const navigate = useNavigate()
  const scrollRef = useRef()
  const storedAppid =
    location?.state?.data?.aptkldata?.defaultdata?.app_id || null
  const storedReg = location?.state?.data?.aptkldata?.defaultdata?.regid_apotek
  const flagcart = location?.state?.flagcart || null

  const defaultParams = {
    a: storedAppid,
    carimobile: keyword,
    limit: 10,
    reg: storedReg,
  }

  const optcarts = {
    name: 'pj-resep-carts',
    flags: 'flagcart',
    flagstok: 'obatid',
  }
  const {cart, carts, cartstoks, edititemcarts, removetocarts} =
    useCarts(optcarts)

  const fetchData = async payload => {
    setError(null)
    try {
      setLoading(true)
      const {data} = await axios.post(
        API7 + '/appenjualanobat-batch/index-etiket',
        QueryString.stringify(payload)
      )

      if (data) setData(data)
    } catch (e) {
      setError('[C0] Gagal mengambil data etiket!')
    } finally {
      setLoading(false)
    }
  }

  const fetchMore = async () => {
    try {
      const payload = {...defaultParams, offset: data.length}
      const {data: resFetchMore} = await axios.post(
        API7 + '/appenjualanobat-batch/index-etiket',
        QueryString.stringify(payload)
      )

      if (!resFetchMore) setHasMore(false)

      setData([...data, ...resFetchMore])
      if (resFetchMore.length < 10) setHasMore(false)
    } catch (error) {
      setHasMore(false)
      setError('[MC0] Gagal mengambil data etiket!')
    }
  }

  useEffect(() => {
    fetchData(defaultParams)
  }, [])

  if (loading && !error)
    return (
      <VmView className="flex justify-center items-center w-full h-screen">
        <VmSpinner className="text-blue-500" />
      </VmView>
    )

  if (!!error && !loading)
    return (
      <VmView className="flex flex-col justify-center items-center w-full h-screen">
        <VmText>{error}</VmText>
        <VmButton onClick={() => fetchData(defaultParams)}>Muat ulang</VmButton>
      </VmView>
    )

  const handleSelect = item => {
    const currentCart = cart[flagcart]
    edititemcarts(
      {
        ...currentCart,
        defaultdata: {
          ...currentCart?.defaultdata,
          pjdcatatan: item?.etiketerangan,
        },
      },
      'flagcart'
    )

    navigate('/prd-penjualan-obat-resep/' + location?.state?.dokid, {
      state: {...item, index: location?.state?.index},
    })
  }

  return (
    <VmView className="h-screen">
      {/* section header */}
      <VmView className="fixed top-0 left-0 right-0 p-5 pb-0 bg-blue4-payment">
        <VmHeader
          className="mb-5"
          HeaderMode="!text-black2-payment"
          HeaderButtonVersion={0}
          HeaderName="Menu"
          HeaderType={'Pembuatan Resep'}
        />

        <VmView className="flex flex-row items-start w-full">
          <VmView className="w-full">
            <Searchbar
              placeholder="Cari Etiket"
              keyword={keyword}
              onChangeCaptureInput={e => setkeyword(e.target.value)}
              onSearch={() => fetchData(defaultParams)}
              onKeyDownCaptureInput={e => {
                if (e.key === 'Enter') fetchData(defaultParams)
              }}
              parentValue={keyword}
              reset={() => fetchData({...defaultParams, carimobile: ''})}
            />
          </VmView>
        </VmView>
      </VmView>

      <VmView
        className="overflow-y-auto pt-[7.5rem] md:pt-14 pb-16 bg-blue4-payment h-full"
        ref={scrollRef}
      >
        {!data.length > 0 && !loading && !error ? (
          <VmView className="flex justify-center items-center w-full h-screen">
            <VmText>Tidak ada data!</VmText>
          </VmView>
        ) : (
          <InfiniteScroll
            hasMore={hasMore}
            loadMore={fetchMore}
            loader={
              <VmView
                key={0}
                className="mt-6 mb-4 items-center justify-center text-center"
              >
                <VmSpinner size="md" color="primary" />
              </VmView>
            }
            useWindow={false}
            getScrollParent={() => scrollRef.current}
          >
            <VmView className="p-5">
              {data.map((item, i) => {
                return (
                  <VmView
                    key={i}
                    className="bg-white px-4 py-3 rounded-xl mb-4 flex items-start justify-between"
                    onClick={() => handleSelect(item)}
                  >
                    <VmText className="text-gray-700">
                      <span className="font-semibold">{item.etinama}</span>
                      {' - '}
                      {item.etiketerangan}
                    </VmText>

                    <VmButton className="ml-4" hidering={true}>
                      Pilih
                    </VmButton>
                  </VmView>
                )
              })}
            </VmView>
          </InfiniteScroll>
        )}
      </VmView>

      <Footer
        title="Tambah"
        backButton
        backButtonTitle="Kembali"
        backHandler={() => navigate(-1)}
        actionHandler={() =>
          navigate('/prd-penjualan-obat-resep/etiket-add', {
            state: {a: storedAppid, reg: storedReg},
          })
        }
      />
    </VmView>
  )
}

export default Etiket
